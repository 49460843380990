import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ББЗЗ дэх үнэт цаасны мэдээллийг хэрхэн харах вэ?",
  "description": "",
  "author": "OTC help",
  "category": "securities",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`ББЗЗ дэх үнэт цаасны мэдээллийг хэрхэн харах вэ?`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Үндсэн цэснээс `}<strong parentName="p">{`[Security]`}</strong>{` хэсгийг сонгон ББЗЗ дэх бүх үнэт цаасны жагсаалтыг Анхдагч болон Хоёрдогч зах зээлээр ялган харах, мөн хайлтын хэсэгт нэрээр хайх боломжтой.
Жагсаалтаас хэрэглэгч бүх үнэт цаасны хураангуй мэдээлэл болон арилжааны төлөвийг харах боломжтой.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Хэрэглэгч сонирхсон үнэт цаасны нэр дээр дарснаар тухайн үнэт цааасны дэлгэрэнгүй мэдээллийн хэсэгт очно.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Сонгосон үнэт цаас нь тухайн оролцогч андеррайтерын үүсгэсэн үнэт цаас байх тохиолдолд `}<strong parentName="p">{`[Disclosure]`}</strong>{` хэсэгт `}<strong parentName="p">{`[New Disclosure]`}</strong>{` товчийг дарж холбогдох явцын мэдээ, мэдээллийг нийтлэн оролцогчдод мэдээллэнэ.`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Security]`}</strong>{` хэсэгт жагсаалтаас тохирох үнэт цаасыг сонгоно`}</li>
          <li parentName="ul"><strong parentName="li">{`[Title]`}</strong>{` хэсэгт мэдээ, мэдээллийн гарчиг оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[Description]`}</strong>{` хэсэгт мэдээллийн товч тайлбар оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[Form type]`}</strong>{` хэсэгт `}{`[File]`}{` буюу бэлэн файл хавсаргах эсвэл `}{`[Form]`}{` хэсгийг дарж платформ дахь нэмэлт мэдээллийн хэсгүүдийг бөглөн `}<strong parentName="li">{`[Publish]`}</strong>{` дарж явцын мэдээг нийтэлнэ.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Нийтлэгдсэн явцын мэдээг `}<strong parentName="p">{`[Security]`}{`-`}{`[Disclosure]`}</strong>{` хэсэг болон үндсэн цэсний `}<strong parentName="p">{`[Security Disclosure]`}</strong>{` хэсгээс харах боломжтой.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Нийтлэгдсэн явцын мэдээг шинэчлэх, засварлах шаардлагатай тохиолдолд явцын мэдээний баруун булан дахь `}<strong parentName="p">{`[Request Update]`}</strong>{` товчийг дарж платформын админд засах хүсэлт илгээнэ.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      